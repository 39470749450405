<template>
  <div>
    <div v-if="checkPropose && refusal" class="alert  " role="alert" >
      <div class="d-flex justify-content-center">
        <img :src="publicPath + 'media/svg/Vector_11.png'" class="logo-edutalk" alt="Edutalk Tư Vấn"
             aria-roledescription="logo">
      </div>
      <h3 class="text-center mt-4">
        Bạn vừa từ chối đơn đề xuất {{ changeDataCategory(data.category) }} : {{ data.user ? data.user.name : '' }}
      </h3>
    </div>
    <div v-if="checkPropose && !refusal" class="alert  " role="alert" >
      <div class="d-flex justify-content-center">
        <img :src="publicPath + 'media/svg/Vector_11.png'" class="logo-edutalk" alt="Edutalk Tư Vấn"
             aria-roledescription="logo">
      </div>
      <h3 class="text-center mt-4">
        Bạn vừa chấp nhận đơn đề xuất {{ changeDataCategory(data.category) }}: {{ data.user ? data.user.name : '' }}
      </h3>
    </div>
    <div v-if="cancel" class="alert  " role="alert" >
      <div class="d-flex justify-content-center">
        <img :src="publicPath + 'media/svg/Vector.png'" class="logo-edutalk" alt="Edutalk Tư Vấn"
             aria-roledescription="logo">
      </div>
      <h3 class="text-center mt-4">
        Đơn đề xuất {{ changeDataCategory(data.category) }} của {{ data.user ? data.user.name : '' }} chưa được xử lý. Vui lòng duyệt lại sau.
      </h3>
    </div>
    <div v-if="data && data.check3Day" class="alert  " role="alert" >
      <div class="d-flex justify-content-center">
        <img :src="publicPath + 'media/svg/Vector.png'" class="logo-edutalk" alt="Edutalk Tư Vấn"
             aria-roledescription="logo">
      </div>
      <h3 class="text-center mt-4">
        Đơn đề xuất {{ changeDataCategory(data.category) }} của {{ data.user ? data.user.name : '' }} đã hết thời hạn xử lý.
      </h3>
    </div>
    <div v-if="!checkPropose && data && data.status != 0 && !data.check3Day" class="alert  " role="alert" >
      <div class="d-flex justify-content-center">
        <img :src="publicPath + 'media/svg/Vector.png'" class="logo-edutalk" alt="Edutalk Tư Vấn"
             aria-roledescription="logo">
      </div>
      <h3 class="text-center mt-4">
        Đơn đề xuất {{ changeDataCategory(data.category) }} của {{ data.user ? data.user.name : '' }} đã được xử lý.
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmPropose",
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    refusal: {
      type: String,
      default: () => {
        return '';
      },
    },
    checkPropose: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    cancel: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    publicPath: {
      type: String,
      default: () => {
        return '';
      },
    },
  },
  methods: {
    changeDataCategory(category) {
      let text = '';
      switch (category) {
        case 0:
          text = 'xin nghỉ phép';
          break;
        case 1:
          text = 'tăng ca';
          break;
        case 2:
          text = 'đổi ca làm việc';
          break;
        case 3:
          text = 'bổ sung giờ làm việc ngoài văn phòng';
          break;
      }
      return text;
    }
  }
}
</script>

<style scoped>

</style>