<template>
  <div style="background: #f3f3f5">
    <div style="height: 50px">
    </div>
    <leave @confirm="updateProposes" :data="data" :refusal="refusal" v-if="category == 0 && data"></leave>
    <overtime @confirm="updateProposesOvertime" :data="data" v-else-if="category == 1" :refusal="refusal"></overtime>
    <please-change-shi-ift @confirm="updateProposes" :data="data" v-else-if="category == 2" :refusal="refusal"></please-change-shi-ift>
    <add-working-hours @confirm="updateProposes" :data="data" v-else-if="category == 3" :refusal="refusal"></add-working-hours>
  </div>

</template>

<script>
import {CHECK_SHOW, UPDATE_PROPOSES_BY_UUID} from "../../../core/services/store/user/users.module";
import Leave from "./Compoment/Leave";
import Overtime from "./Compoment/Overtime";
import AddWorkingHours from "./Compoment/AddWorkingHours";
import PleaseChangeShiIft from "./Compoment/PleaseChangeShiIft";

export default {
  name: "FeedbackEmailProposes",
  components: {
    Leave,
    Overtime,
    PleaseChangeShiIft,
    AddWorkingHours,
  },
  data() {
    return {
      publicPath: process.env.VUE_APP_BASE_URL,
      uuid: '',
      done: 1,
      proposes: [],
      refusal: 'a',
      status: this.$route.query.status,
      reason: '',
      category: '',
      check: true,
      data: {},
      type_arise: 0,
      mess: '',
    }
  },
  mounted() {
    this.uuid = this.$route.query.uuid;
    this.refusal = this.$route.query.refusal;
    this.checkShow();
  },
  methods: {
    checkShow() {
      this.$store.dispatch(CHECK_SHOW, this.$route.query).then((response) => {
        this.status = response.data.status;
        this.data = response.data;
        this.category = response.data.category;
      })
    },
    updateProposes(reason) {
      if (reason == '' && this.refusal) {
        return;
      }
      this.$store.dispatch(UPDATE_PROPOSES_BY_UUID, {
        uuid: this.$route.query.uuid,
        code: this.$route.query.uuid_propose,
        refusal: this.refusal,
        reason: reason,
        category: this.category,
        start_date: this.data.start_date,
        id_user_handle: this.$route.query.id_user_handle,
        type_arise: this.type_arise,
        phone: this.data.phone,
        end_date: this.data.end_date,
        general_leave: this.data.general_leave,
        start_shift_off: this.data.start_shift_off,
        type_leave: this.data.type_leave,
      }).then((response) => {
        if (response?.data) {

        }
      }).catch((response) => {
      })
    },
    action() {
      this.updateProposes();
      this.status = 2;

    },
    updateProposesOvertime(reason, type_arise) {
      this.type_arise = type_arise;
      this.updateProposes(reason);
    }
  }
}
</script>

<style scoped>
  .bg-violet {
    background: #472f92 !important;
  }
  .gift {
    display: grid;
  }
  .center {
    height: 1000px;
    padding-top: 300px;
  }
  .bg-violet {
    color: white;
    background: blueviolet;
  }
</style>
