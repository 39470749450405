<template>
  <div>
    <div class="m-6 pt-4">
      <div v-if="!cancel && data && data.status == 0 && data.check3Day == false">
        <div class="text-center">
          <h3 class="bold" v-if="!refusal">Duyệt đề xuất nghỉ phép</h3>
          <h3 class="bold" v-else>Từ chối đề xuất nghỉ phép</h3>
        </div>
        <div class="text-left d-flex justify-content-center col-md-12 pb-4">
          <div class="col-md-5 rounded bg-white pb-6">
            <div class="col-md-12 d-flex mt-3">
              <div class="col-md-7 text-left">Địa chỉ email: </div>
              <div class="col-md-5 text-left">{{ data.user ? data.user.email : '' }}</div>
            </div>
            <div class="col-md-12 d-flex mt-3">
              <div class="col-md-7 text-left">Mã nhân sự: </div>
              <div class="col-md-5 text-left">{{ data.user_id + 100000 }}</div>
            </div>
            <div class="col-md-12 d-flex mt-3">
              <div class="col-md-7 text-left">Họ và tên: </div>
              <div class="col-md-5 text-left">{{ data.user ? data.user.name : '' }}</div>
            </div>
            <div class="col-md-12 d-flex mt-3">
              <div class="col-md-7 text-left">Vị trí: </div>
              <div class="col-md-5 text-left">{{ data.user && data.user.profile && data.user.profile.account_type ? data.user.profile.account_type.name : '' }}</div>
            </div>
            <div class="col-md-12 d-flex mt-3">
              <div class="col-md-7 text-left">Phòng ban: </div>
              <div class="col-md-5 text-left">{{ data.department_user.department ? data.department_user.department.name : '' }}</div>
            </div>
            <div class="col-md-12 d-flex mt-3">
              <div class="col-md-7 text-left">Tổng số công nghỉ phép: </div>
              <div class="col-md-5 text-left">{{ data.general_leave }}</div>
            </div>
            <div class="col-md-12 d-flex mt-3">
              <div class="col-md-7 text-left">Ngày bắt đầu nghỉ phép: </div>
              <div class="col-md-5 text-left">{{ data.start_date |formatDate}}</div>
            </div>
            <div class="col-md-12 d-flex mt-3">
              <div class="col-md-7 text-left">Buổi bắt đầu nghỉ phép: </div>
              <div class="col-md-5 text-left">{{ data.start_shift_off == 1 ? 'Ca sáng' : (data.start_shift_off == 2 ? 'Ca chiều' : 'Ca tối') }}</div>
            </div>
            <div class="col-md-12 d-flex mt-3">
              <div class="col-md-7 text-left">Ngày kết thúc nghỉ phép: </div>
              <div class="col-md-5 text-left">{{ data.end_date |formatDate}}</div>
            </div>
            <div class="col-md-12 d-flex mt-3">
              <div class="col-md-7 text-left">Lý do nghỉ phép: </div>
              <div class="col-md-5 text-left">{{ data.reason }}</div>
            </div>
            <div class="col-md-12 d-flex mt-3">
              <div class="col-md-7 text-left">Số điện thoại liên hệ khi cần: </div>
              <div class="col-md-5 text-left">{{ data.phone }}</div>
            </div>
            <div class="col-md-12 d-flex mt-3" v-if="refusal">
              <div class="col-md-7 text-left bold">Nhập lý do từ chối <span class="text-danger">*</span></div>
            </div>
            <div class="col-md-12 d-flex mt-3" v-if="refusal">
              <textarea v-model="reason" class="w-100 rounded" name="" id="" cols="100" rows="10"></textarea>
            </div>
            <p v-if="message" class="col-md-12 text-danger">{{ message }}</p>

            <div class="col-md-12 d-flex justify-content-center mt-3">
              <el-button @click="confirmCancel">Hủy bỏ</el-button>
              <el-button @click="confirm">Xác nhận</el-button>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="data.id">
        <ConfirmPropose
          :data="data"
          :checkPropose="checkPropose"
          :cancel="cancel"
          :publicPath="publicPath"
          :refusal="refusal"
        ></ConfirmPropose>
      </div>
    </div>
  </div>
</template>

<script>
import ConfirmPropose from "./ConfirmPropose";

export default {
  name: "Leave",
  components: {
    ConfirmPropose
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    refusal: {
      type: String,
      default: () => {
        return '';
      },
    },
  },
  data() {
    return {
      publicPath: process.env.VUE_APP_BASE_URL,
      checkPropose: false,
      cancel: false,
      reason: '',
      message: ''
    }
  },
  methods: {
    confirm() {
      if (this.refusal && this.reason == '') {
        this.message = 'Lý do từ chối không được để trống';
        return;
      }

      this.checkPropose = true;
      this.$emit('confirm', this.reason);
      // eslint-disable-next-line vue/no-mutating-props
      this.data.status = 1;
      this.message = '';


    },
    confirmCancel() {
      this.cancel = true;
    }
  }
}
</script>

<style scoped>

</style>